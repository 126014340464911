<template>
  <component v-if="component" :is="component" :model="props.model" :options="props.options" />
</template>
<script lang="ts" setup>
import { type IContentItem } from '@kontent-ai/delivery-sdk'
import type { LinkedItemOptions } from '~/types/LinkedItemOptions.ts'
const props = defineProps<{ model: IContentItem; options?: LinkedItemOptions }>()
const component = componentTypeMapper(props.model.system.type)

</script>
