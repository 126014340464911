<template>
  <SmartLinkItemComponent
    v-if="model"
    :model="model"
    :component="!model.system.workflow"
    :id="model.system.id"
  >
    <SmartLinkStandaloneElement :codename="type.elements.title.codename">
          <section
            class="accordion container">
            <h2 v-if="data.title" class="accordion__component-title">{{ data.title }}</h2>
            <LinkedItemComponent
              v-for="(item, i) in data.items"
              :model="item"
              :index="i"
              :is-open="i == 0 && data.isExpanded"
            />
          </section>
    </SmartLinkStandaloneElement>
  </SmartLinkItemComponent>
</template>

<script lang="ts" setup>
import type { AccordionListModel } from "~/models";
import { type AccordionItemModel, contentTypes } from "~/models";

const type = contentTypes._accordion_list;
const pageStore = usePageStore();
const props = defineProps<{ model: AccordionListModel }>();

const data = {
  title: props.model.elements.title.value,
  items: props.model.elements.accordionItems.value.map(item => pageStore.getLinkedItem<AccordionItemModel>(item)),
  isExpanded: props.model.elements.defaultState.value.find(o => o.codename == 'expanded') != null,
};

</script>
